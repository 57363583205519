<template>
  <div id="skills" class="w-full p-3 text-white ring-1 ring-white rounded-md">
    <skill-card
      v-for="(s, i) in skills"
      :key="'skill' + i"
      :name="s.name"
      :score="s.score"
    />
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import SkillCard from '../SkillCard.vue'

export default {
  components: { SkillCard },
  setup () {
    const store = useStore()

    const skills = computed(() => store.getters.skills)

    return {
      skills
    }
  }
}
</script>
