<template>
  <div class="group inline-block">
    <button
      class="outline-none focus:outline-none border px-3 py-1 bg-dark ring-1 ring-white rounded-sm flex items-center min-w-48"
      @click="open = !open"
    >
      <span class="pr-1 font-semibold flex-1">{{ modelValue }}</span>
      <span>
        <svg
          class="fill-current h-4 w-4 transform transition duration-150 ease-in-out"
          :class="{
            'group-hover:-rotate-180': open
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </span>
    </button>
    <ul
      v-if="open"
      class="z-10 bg-dark ring-1 ring-white border rounded-sm transform scale-1 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-48 max-h-60 overflow-y-auto"
    >
      <li
        v-for="(o, i) in options"
        :key="'menu-' + i"
        class="rounded-sm px-3 py-1 hover:bg-white hover:text-black"
        :class="{
          'text-black': o === modelValue,
          'bg-white': o === modelValue
        }"
        @click="onSelect(o)"
      >
        {{ o }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props: {
    modelValue: {
      type: [String],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  setup (props, ctx) {
    const open = ref(false)

    const onSelect = (val) => {
      open.value = false
      ctx.emit('update:modelValue', val)
    }

    return {
      open,
      onSelect
    }
  }
}
</script>

<style>
  /* since nested groupes are not supported we have to use
     regular css for the nested dropdowns
  */
  li>ul                 { transform: translatex(100%) scale(0) }
  li:hover>ul           { transform: translatex(101%) scale(1) }
  li > button svg       { transform: rotate(-90deg) }
  li:hover > button svg { transform: rotate(-270deg) }

  /* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
     See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
     for implementation with config file
  */
  /* .group:hover .group-hover\:scale-100 { transform: scale(1) } */
  .group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
  .scale-0 { transform: scale(0) }
  .min-w-48 { min-width: 12rem }
</style>
