<template>
  <div id="experiences" class="w-full mb-5">
    <div class="text-5xl mb-5">Experiences</div>
    <div class="grid grid-cols-4 gap-5">
      <div class="col-span-4 md:col-span-1">
        <education-content />
        <skill-content class="mt-5" />
      </div>
      <div class="col-span-4 md:col-span-3">
        <work-timeline />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import WorkTimeline from '../WorkTimeline.vue'
import SkillContent from '../contents/SkillContent.vue'
import EducationContent from './EducationContent.vue'
import { updateHash } from '@/utils'
import { onMounted } from '@vue/runtime-core'

export default {
  components: {
    WorkTimeline,
    SkillContent,
    EducationContent
  },
  setup () {
    const store = useStore()

    onMounted(() => {
      updateHash('#experiences')
    })

    return {
      profile: store.getters.profile,
      fullName: store.getters.fullName
    }
  }
}
</script>
