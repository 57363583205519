<template>
  <div id="certificates" class="w-full mb-5">
    <div class="text-5xl">Certificates</div>
    <dropdown class="my-5" v-model="search" :options="publishers"/>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-1">
      <certificate-card
        v-for="(p, i) in certificates"
        :key="'certificate-' + i"
        :item="p"
        class="col-span-full md:col-span-1 lg:col-span-1 self-start"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Dropdown from '../Dropdown.vue'
import { updateHash } from '@/utils'
import CertificateCard from '../CertificateCard.vue'

export default {
  components: {
    Dropdown,
    CertificateCard
  },
  setup () {
    const store = useStore()
    const search = ref('All')

    const certificates = computed(() => {
      const all = store.getters.certificates

      if (!search.value || search.value === 'All') {
        return all
      }

      return all.filter((e) => {
        return e?.publisher === search.value
      })
    })

    const publishers = computed(() => {
      const all = store.getters.certificates

      const _publishers = all.map((e) => {
        return e?.publisher
      })

      const merged = [].concat.apply(['All'], _publishers)

      const unique = [...new Set(merged)]

      unique.sort()

      return unique
    })

    onMounted(() => {
      updateHash('#certificates')
    })

    return {
      certificates,
      search,
      publishers
    }
  }
}
</script>
