<template>
  <div class="flex justify-between">
    <span>{{ name }}</span>
    <rating-star :range="10" :value="score" />
  </div>
</template>

<script>
import RatingStar from './RatingStar.vue'

export default {
  components: { RatingStar },
  props: {
    name: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      required: true
    }
  }
}
</script>
