<template>
  <div id="projects" class="w-full mb-5">
    <div class="text-5xl">Projects</div>
    <dropdown class="my-5" v-model="search" :options="techs"/>
    <div class="grid grid-cols-3 gap-1">
      <project-card
        v-for="(p, i) in projects"
        :key="'project-' + i"
        :item="p"
        class="col-span-3 md:col-span-1 self-start"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Dropdown from '../Dropdown.vue'
import ProjectCard from '../ProjectCard.vue'
import { updateHash } from '@/utils'

export default {
  components: { Dropdown, ProjectCard },
  setup () {
    const store = useStore()
    const search = ref('All')

    const projects = computed(() => {
      const all = store.getters.projects

      if (!search.value || search.value === 'All') {
        return all
      }

      return all.filter((e) => {
        const _techs = e?.techs?.map(t => t?.name)

        return _techs?.includes(search.value)
      })
    })

    const techs = computed(() => {
      const all = store.getters.projects

      const _techs = all.map((e) => {
        const _techs = e?.techs?.map(t => t?.name)

        return _techs
      })

      const merged = [].concat.apply(['All'], _techs)

      const unique = [...new Set(merged)]

      unique.sort()

      return unique
    })

    onMounted(() => {
      updateHash('#projects')
    })

    return {
      projects,
      search,
      techs
    }
  }
}
</script>
