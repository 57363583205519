<template>
  <div id="educations" class="w-full p-3 text-white ring-1 ring-white rounded-md">
    <education-card
      v-for="(s, i) in educations"
      :key="'education-' + i"
      :item="s"
    />
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import EducationCard from '../EducationCard.vue'

export default {
  components: { EducationCard },
  setup () {
    const store = useStore()

    const educations = computed(() => store.getters.educations)

    return {
      educations
    }
  }
}
</script>
