<template>
  <div>
    <div v-if="item.name" class="text-xl font-bold">{{ item.name }}</div>
    <div v-else class="animate-pulse w-3/4 h-3 rounded-sm bg-gray-500" />
    <div class="text-lg mt-1">
      <span class="capitalize">{{ item.degree }}</span>
      <span> of {{ item.majors }}</span>
    </div>
    <div class="mt-1">
      <fa-icon class="mr-1" icon="map-marker-alt" />
      {{ item.location }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  }
}
</script>
