<template>
  <div :class="right ? 'col-start-5 col-end-6 mr-10 md:mx-auto relative' : 'col-start-5 col-end-6 md:mx-auto relative mr-10'">
    <div class="h-full w-6 flex items-center justify-center">
      <div class="h-full w-1 bg-white pointer-events-none"></div>
    </div>
    <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-dark shadow ring-2 ring-white"></div>
  </div>
</template>

<script>
export default {
  props: {
    right: {
      type: Boolean,
      default: false
    }
  }
}
</script>
