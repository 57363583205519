<template>
  <div class="text-left h-full bg-dark text-white flex justify-center">
    <div class="container">
      <div id="top" />
      <profile-content />
      <experiences-content />
      <projects-content />
      <certificates-content />
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ProfileContent from '../components/contents/ProfileContent.vue'
import ExperiencesContent from '../components/contents/ExperiencesContent.vue'
import ProjectsContent from '../components/contents/ProjectsContent.vue'
import CertificatesContent from '../components/contents/CertificatesContent.vue'

export default {
  name: 'Home',
  components: {
    ProfileContent,
    ExperiencesContent,
    ProjectsContent,
    CertificatesContent
  },
  setup (props, ctx) {
    const store = useStore()

    onMounted(async () => {
      await store.dispatch('getResume')
    })

    return {
      onMounted
    }
  }
}
</script>
