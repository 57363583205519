<template>
  <div class="relative flex flex-col items-center justify-center my-1 md:my-5" data-aos="zoom-in">
    <div class="container">
      <div class="max-w-md w-full bg-dark shadow-xl rounded-xl p-6 hover:ring-1 ring-white">
        <!-- <pdf :src="item.url" /> -->
        <span class="text-sm rounded-md ring-1 ring-white px-2 py-1">
          {{ item.publisher }}
        </span>
        <div class="font-bold cursor-pointer mt-1">
          <a :href="item.url" target="_blank">
            {{ removePrefix(item.title) }}
          </a>
        </div>
        <div class="text-xs mt-1">
          {{ validDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { formatDate } from '@/utils'
// import Pdf from '@jbtje/vue3pdf'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    // Pdf
  },
  setup (props) {
    const splideOptions = ref({
      rewind: true,
      autoplay: true
    })

    const isForever = computed(() => Boolean(props.item.isForever))

    const validDate = computed(() => {
      if (isForever.value) {
        return 'Forever'
      }

      const publishedAt = formatDate(props.item.publishedAt, 'DD MMMM YYYY')
      const expiredAt = formatDate(props.item.expiredAt, 'DD MMMM YYYY')

      return `${publishedAt} - ${expiredAt}`
    })

    const removePrefix = (text) => {
      if (!text) {
        return ''
      }

      return text.replace('[Academy]', '').replace('[Event]', '').trim()
    }

    return {
      splideOptions,
      removePrefix,
      validDate
    }
  }
}
</script>

<style lang="scss">
.splide__arrow {
  width: 1.2em;
  height: 1.2em;

  & svg {
    width: 0.7em;
    height: 0.7em;
  }
}
</style>
