<template>
  <div id="me" class="w-full min-h-screen flex">
    <div class="self-center">
      <div class="greeting text-5xl font-bold" />
      <div class="whoami text-5xl mt-3" />
      <div class="bio text-2xl my-3" />
    </div>
  </div>
</template>

<script>
import { computed, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Typed from 'typed.js'
import { sleep } from '@/utils'

export default {
  setup () {
    const store = useStore()

    const fullName = computed(() => store.getters.fullName)
    const profile = computed(() => store.getters.profile)

    watch(profile.value, async (val) => {
      if (val) {
        await sleep(1000)
        // eslint-disable-next-line no-unused-vars
        const greeting = new Typed('.greeting', {
          strings: ['Hello,'],
          typeSpeed: 100,
          showCursor: false,
          onComplete: (_) => {
            // eslint-disable-next-line no-unused-vars
            const whoami = new Typed('.whoami', {
              strings: [`I'm ${fullName.value ?? ''},`],
              typeSpeed: 100,
              showCursor: false,
              onComplete: (_) => {
                // eslint-disable-next-line no-unused-vars
                const bio = new Typed('.bio', {
                  strings: [profile.value?.bio ?? ''],
                  typeSpeed: 80,
                  showCursor: false
                })
              }
            })
          }
        })
      }
    }, {
      immediate: true,
      deep: true
    })

    return {
      profile: store.getters.profile,
      fullName
    }
  }
}
</script>
