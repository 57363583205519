<template>
  <div class="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
    <work-card-dot v-for="(w, i) in works" :key="'work-' + i" :item="w" :right="i % 2 === 0" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import WorkCardDot from './WorkCardDot.vue'
import { computed } from '@vue/runtime-core'

export default {
  components: { WorkCardDot },
  setup (props, ctx) {
    const store = useStore()

    const works = computed(() => store.getters.works ?? [])

    return {
      works
    }
  }
}
</script>
