<template>
  <div :class="right ? 'flex md:contents' : 'flex flex-row-reverse md:contents'" data-aos="zoom-in">
    <dot-timeline v-if="right" right />
    <work-card :item="item" :right="right" />
    <dot-timeline v-if="!right" />
  </div>
</template>

<script>
import DotTimeline from './DotTimeline.vue'
import WorkCard from './WorkCard.vue'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DotTimeline,
    WorkCard
  }
}
</script>
