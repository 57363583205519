<template>
  <div>
    <fa-icon
      v-for="(c, i) in range"
      :key="'rate-' + i"
      class="mr-1"
      :icon="[(i + 1) <= value ? 'fas' : 'far', 'star']"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    range: {
      type: Number,
      required: true
    }
  }
}
</script>
