<template>
  <!-- <div
    class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    style="background-image: url(https://images.unsplash.com/photo-1604262725913-1c415cd27564?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2142&q=80);"
    id="modal-id"
  >
    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
  </div> -->
  <div class="relative flex flex-col items-center justify-center my-1 md:my-5" data-aos="zoom-in">
    <div class="container">
      <div class="max-w-md w-full bg-dark shadow-xl rounded-xl p-6 hover:ring-1 ring-white">
        <div class="flex flex-col ">
          <div class="">
            <div class="relative h-62 w-full mb-3">
              <a
                v-if="item.url"
                class="absolute left-0 ring-1 ring-white px-2 py-1 m-3 rounded-full cursor-pointer z-20"
                :href="item.url"
                target="_blank"
              >
                <fa-icon icon="external-link-square-alt" />
              </a>
              <a
                v-if="item.repository"
                class="absolute right-0 ring-1 ring-white px-2 py-1 m-3 rounded-full cursor-pointer z-20"
                :href="item.repository"
                target="_blank"
              >
                <fa-icon :icon="['fab', getRepoIcon(item.repository)]" />
              </a>
              <splide v-if="item.medias.length > 0" :option="splideOptions" :key="`media-wrapper-${item.id}`">
                <splide-slide
                  v-for="(m, iM) in item.medias"
                  :key="`media-${item.id}-${iM}`"
                >
                  <img
                    :src="m.url"
                    alt="Just a flower"
                    class="h-60 w-full object-fill rounded-2xl"
                  >
                </splide-slide>
              </splide>
              <img
                v-else
                :src="defaultImage"
                alt="Just a flower"
                class="h-60 w-full object-fill rounded-2xl opacity-30"
              >
            </div>
            <div class="flex-auto justify-evenly">
              <div class="flex flex-wrap flex-col">
                <div class="w-full flex-wrap text-sm flex items-center text-white">
                  <span
                    v-for="(t, iT) in item.techs"
                    :key="`tech-${item.id}-${iT}`"
                    class="mr-2 text-white bg-black rounded-xl ring-1 ring-white py-1 px-2 text-xs whitespace-nowrap mb-2"
                  >
                    {{ t.name }}
                  </span>
                </div>
                <div class="flex items-center w-full justify-between min-w-0 mt-2">
                  <h2 class="text-lg mr-auto cursor-pointer text-white font-semibold">
                    <a :href="item.url" target="_blank">
                      {{ item.title }}
                    </a>
                  </h2>
                </div>
                <span class="text-sm flex-1 mt-1">
                  {{ item.description }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup () {
    const splideOptions = ref({
      rewind: true,
      autoplay: true
    })

    const defaultImage = ref('https://blog.nscsports.org/wp-content/uploads/2014/10/default-img.gif')

    const getRepoIcon = (url) => {
      if (url.includes('github')) {
        return 'github'
      } else if (url.includes('gitlab')) {
        return 'gitlab'
      } else if (url.includes('bitbucket')) {
        return 'bitbucket'
      }

      return 'git'
    }

    return {
      getRepoIcon,
      defaultImage,
      splideOptions
    }
  }
}
</script>

<style lang="scss">
.splide__arrow {
  width: 1.2em;
  height: 1.2em;

  & svg {
    width: 0.7em;
    height: 0.7em;
  }
}
</style>
