<template>
  <div
    :class="right ? 'bg-dark col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md ring-1 ring-white' : 'bg-dark col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md ring-1 ring-white'"
  >
    <span class="italic">{{ dateRange }}</span>
    <h3 class="font-semibold text-white text-lg my-1">{{ item.title }}</h3>
    <p class="leading-tight text-white text-justify">
      <fa-icon class="mr-2" :icon="['far', 'building']" />
      <span>
        {{ item.companyName }}
      </span>
    </p>
    <p class="leading-tight text-white text-justify text-sm mt-2">
      {{ item.description }}
    </p>
  </div>

</template>

<script>
import { formatDate } from '@/utils'
import { computed } from '@vue/runtime-core'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const dateRange = computed(() => {
      const start = formatDate(props.item.start, 'MMM YYYY')
      const end = formatDate(props.item.end, 'MMM YYYY')
      const isCurrent = Boolean(props.item.isCurrent)

      return `${start} - ${isCurrent ? 'now' : end}`
    })

    return {
      formatDate,
      dateRange
    }
  }
}
</script>
